<template>
  <v-app>
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card
              class="elevation-12"
            >
              <v-toolbar
                dark
                flat
                color="#4268b1"
                align="center"
              >
                <v-toolbar-title>Login</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-img
                src="@/assets/nrsLogo.png"
                alt="Logo"
                app
                class="mt-2 mx-auto"
                width="120"
              />
              <h1
                class="text-center"
              >
                DMIS
              </h1>
              <v-snackbar
                v-model="snackbar"
                top
                :color="color"
                :timeout="timeout"
              >
                {{ text }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="blue"
                    icon
                    v-bind="attrs"
                    @click="setSnackbar(false)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-snackbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="username"
                    label="Username"
                    name="login"
                    type="text"
                    outlined
                    color="#4268b1"
                    @keyup.enter="authenticate"
                  />
                  <v-text-field
                    id="password"
                    v-model="password"
                    label="Password"
                    name="password"
                    outlined
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    @click:append.prevent="show = !show"
                    @keyup.enter="authenticate"
                    color="#4268b1"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#4268b1"
                  width="100px"
                  :disabled="username == '' || password == ''"
                  @click="authenticate"
                  class="mb-8 mx-2"
                >
                  Login
                </v-btn>
                <v-spacer />
                <v-btn
                  outlined
                  link
                  text
                  @click="requestToken"
                  class="mb-8 mx-2"
                >
                  Reset Password
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  data () {
    return {
      username: '',
      password: '',
      show: false
    }
  },
  computed: {
    ...mapState('store', ['snackbar', 'color', 'text', 'timeout', 'storeProvinces']),
    ...mapGetters('store', ['isAuthenticated'])
  },
  beforeCreate () {
    localStorage.clear()
    sessionStorage.clear()
  },

  methods: {
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT'
    }),

    authenticate () {
      this.$authenticationService.login(this.username, this.password)
        .then(resp => {
          this.password = ''
          if (resp.status === 'success') {
            this.setAuthentication(true)
            this.setLoggedInUser()
            this.readCurrentCenter()
            this.loadDecisions()
            this.loadCommitteeMembersPositions()
            this.loadEducationLevels()
            this.loadStoreProvinces()
            this.loadDeviantActs()
          } else if (resp.message === 'Bad credentials') {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Username or password is incorrect, Please login with correct credentials!')
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Error! ' + resp.message)
          }
        })
    },
    requestToken () {
      this.$router.push('/token-request')
    },
    ...mapActions('store', [
      // loadCurrentUser: 'setLoggedInUser',
      'loadDecisions',
      'loadCommitteeMembersPositions',
      'loadEducationLevels',
      'loadStoreProvinces',
      'loadDeviantActs'
    ]),
    ...mapMutations('store', {
      setAuthentication: 'SET_AUTHENTICATED',
      setCurrentUser: 'SET_CURRENT_USER',
      setCurrentCenter: 'SET_CURRENT_CENTER'
    }),
    setLoggedInUser () {
      if (this.isAuthenticated) {
        this.$userService.fetchCurrentUser('expand')
          .then(response => {
            // console.log(response.privileges)
            if (response.id) {
              this.setCurrentUser(response)
              this.$router.push('/')
            } else {
              this.setSnackbar(true)
              this.setColor('red')
              this.setText('Error! ' + response.message)
            }
          })
      }
    },
    readCurrentCenter () {
      if (this.isAuthenticated) {
        this.$userService.fetchCurrentCenter()
          .then(response => {
            if (response.id) {
              this.setCurrentCenter(response)
            } else {
              this.setSnackbar(true)
              this.setColor('red')
              this.setText('Error! ' + response.message)
            }
          })
      }
    }
  }

}

</script>
